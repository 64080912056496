import { updatePostError, updatePostStart, updatePostSuc } from './types';
import { putData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';
const updatePost = (values: any) => {
    return (dispatch: any, getState: any) => {
        let queryObject: any = { unit: {}, post: {} };
        //TODO Quick fix on bug, adding this fields, need to find where are added.
        delete values.onSubmit;
        delete values.children;
        delete values.unitId;
        delete values.onSaveData;
        delete values.disabled;
        delete values.savingPost;
        delete values.photos;
        delete values.unit_documents;
        delete values.verifier_id;

        for (const key in values) {
            if (Array.isArray(values[key])) {
                if (values[key].length !== 0) {
                    values[key] = values[key].reduce((total: string, actual: string) => total + `,${actual}`);
                }
            }

            if (key === 'title' || key === 'description') {
                queryObject['post'][`${key}`] = values[key];
            } else if (key === 'estimate_price_amount') {
                queryObject['post']['price_amount'] = values[key];
                queryObject['unit'][`${key}`] = values[key];
            } else if (key === 'estimate_price_currency') {
                queryObject['post']['price_currency'] = values[key];
                queryObject['unit'][`${key}`] = values[key];
            } else if (
                values[key] ||
                values[key] === 0 ||
                values[key] === null ||
                values[key] === '' ||
                values[key] === []
            ) {
                let name = key;
                queryObject['unit'][`${name}`] = values[key];
            }
        }

        dispatch(updatePostStart());
        const postId = getState().postValidation.post.id;
        putData(`posts/${postId}`, queryObject)
            .then((result) => {
                toast(intl.get('PROPERTY_UPDATED'));
                dispatch(updatePostSuc(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(updatePostError(e));
            });
    };
};
export default updatePost;
