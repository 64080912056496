export const updatePostError = (error: any) => {
    return {
        type: 'UPDATE_POST_ERROR',
        payload: {
            error,
        },
    };
};

export const updatePostStart = () => {
    return {
        type: 'UPDATE_POST_STARTED',
    };
};

export const updatePostSuc = (photo: any) => {
    return {
        type: 'UPDATE_POST_SUCCESS',
        payload: photo,
    };
};
